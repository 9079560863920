import gql from 'graphql-tag';

export default gql`
mutation setFolderSettings(
  $folderObject: folders_set_input,
  $folderId: Int
) {
  update_folders(
    where: {
      folder_id: {_eq: $folderId}
    },
    _set: $folderObject) {
      affected_rows
    }
  }
`;
