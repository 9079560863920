import gql from 'graphql-tag';

export default gql`
mutation updateFoldersCIData($folderId: Int!, $data: jsonb) {
  update_folders(
    where: {folder_id: {_eq: $folderId}}
    _append: { corporate_identity: $data }
  ) {
    affected_rows
    returning {
      folder_id
      corporate_identity
    }
  }
}
`;
