import gql from 'graphql-tag';

export default gql`
mutation deletePluginsInFolder($id: Int!, $handle: String!) {
  delete_plugins(
    where: {
      folder_id: {_eq: $id},
      handle: {_eq: $handle}
  }) {
    affected_rows
  }
}
`;
