import gql from 'graphql-tag';

export default gql`
mutation updateProjectsCIData($projectId: Int!, $data: jsonb) {
  update_projects(
    where: {project_id: {_eq: $projectId}}
    _append: { corporate_identity: $data }
  ) {
    affected_rows
    returning {
      corporate_identity
    }
  }
}
`;
