import gql from 'graphql-tag';

export default gql`
mutation updateFolderPlugin(
  $folderId: Int,
  $handle: String!,
  $settings: jsonb!,
  $isActive: Boolean!
) {
  update_plugins(
    where: {
      _and: {
        folder_id: {_eq: $folderId},
        handle: {_eq: $handle}
      }
    }, 
    _set: {
      settings: $settings, 
      enabled: $isActive
    }
  ) {
    affected_rows
  }
}
`;
