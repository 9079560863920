import gql from 'graphql-tag';

export default gql`
mutation deletePluginDataInProject($id: Int!, $handle: String!) {
  delete_plugins(
    where: {
      project_id: {_eq: $id},
      handle: {_eq: $handle}
    }
  ) {
    affected_rows
  }
}
`;
